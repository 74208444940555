<template v-if="post_propos"> 

 <div class="layout-wrapper" style="margin-top: 120px;" > 

  <section class="hero-header">
    <div class="section-inner">
      <div class="hero-header-content">
      <span v-if="this.post_propos" v-html="post_propos.header_ligne01" > </span>
      <span v-if="this.post_propos" v-html="post_propos.header_ligne02" > </span>
      <span v-if="this.post_propos" v-html="post_propos.header_ligne03" > </span>
      <span v-if="this.post_propos" v-html="post_propos.header_ligne04" > </span>
       <span v-if="this.post_propos" v-html="post_propos.header_ligne05" > </span>
      </div>
      <div class="hero-header-profil" >
        <img v-if="this.post_propos"  
        :src="post_propos.section_presentation.thumbnail" loading="lazy" />
        </div>
     
    </div>
  </section>
<section class="section-presentation"> 
  <div class="section-inner">
   <h1 class="home-title skew-title" v-if="this.post_propos" v-html="post_propos.title"></h1> 
    <p v-if="this.post_propos" v-html="post_propos.section_presentation.content"></p>
  </div>
  <div class="skew-back"></div>
   </section>

   <section class="section-stack"> 
     <div class="section-inner">
     <h2 class="skew-title" v-if="this.post_propos" v-html="post_propos.section_presentation.titre"></h2> 
   <div class="stack-wrapper">
      <div class="stack-list">
        <span v-if="this.post_propos"  v-html="post_propos.section_conpetence.titre01"></span>  
        <p  v-if="this.post_propos" v-html="post_propos.section_conpetence.content01"></p>
      </div>
          <div class="stack-list">
          <span  v-if="this.post_propos" v-html="post_propos.section_conpetence.titre02"></span>  
          <p  v-if="this.post_propos" v-html="post_propos.section_conpetence.content02"></p>
        </div>

   </div>
     </div>
   </section>

   </div>

</template>

<script>
 require('@/assets/css/icon_list.css');
 import headful from 'headful';

export default {
  
  name: 'Apropos',
    created() {  this.get_fetchData() },
    data() {  return { post_propos: null,  }  },
  methods: {
    get_fetchData() {
     /* fetch('https://25et7.fr/dev/2022/25et7/get_page/mon-parcourt') */
      fetch('https://25et7.fr/25et7/get_page/mon-parcours')
          .then(async response => { const post_propos_data = await response.json();
         if (post_propos_data) { 
            this.post_propos = post_propos_data;
             headful({
                    title: post_propos_data.seo.title,
                    description: post_propos_data.seo.description,
                });
            
            } })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hero-header .hero-header-content{
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: #569fb9;
  font-family: 'hammersmithoneregular', sans-serif;
  font-size: 60px;
  font-size: 55px;
  justify-content: center;
  line-height: 0.9;
  height: 60vh;
  -webkit-text-stroke: 2px #569fb9;
  position: relative;
  z-index: 99;}
.hero-header .hero-header-content span:nth-child(3){
-webkit-text-fill-color: #e4e0da;
-webkit-text-stroke: 1px #569fb9;}
.hero-header .hero-header-profil{
  position: absolute;
  right: -50px;
  top: 0;
  top: -80px;
  width: 600px;}
.hero-header .hero-header-profil img{
  z-index: 9;
  position: relative;}
.hero-header .hero-header-profil::after {
  content: "";
  width: 100%;
  background-color: #eb8a00;
  height: 150px;
  position: absolute;
  bottom: -50px;
  transform: skew(0deg, -13deg);}
.section-presentation{
  position: relative;
  min-height: auto;}
.section-presentation .section-inner{margin-bottom: 200px;}
.section-presentation .home-title{
  -webkit-text-stroke: 3px #465054;
  text-stroke: 3px #465054;
  -webkit-text-fill-color: #465054;
  text-fill-color: #465054;
  width: 85%;
  text-transform: uppercase;}
.section-presentation p{
  width: 768px;
  margin-left: auto;
  margin-right: 0;
  color: #e4e0da;
  white-space: break-spaces;
  position: relative;
  z-index: 99;}
.section-presentation .skew-back{
  top: 26px;
  background-color: #465054;
  height: calc(100% + 175px);
 transition: top 0.5s ease, height top 0.5s ease}

/** Stack */
.section-stack{
min-height: auto;margin-top: -45px;}
.section-stack .skew-title{
  -webkit-text-stroke: 3px #e4e0da;
  text-stroke: 3px #e4e0da;
  -webkit-text-fill-color: #e4e0da;
  text-fill-color: #e4e0da;
  width: 100%;
  text-transform: uppercase;
}
.stack-wrapper{
margin-top: 150px;
display: flex;
justify-content: space-around;}

.stack-list {
  display: flex;
flex-direction: column;
width: 35%;}
.stack-list span{
font-size: 17px;
font-weight: bolder;
margin-bottom: 15px;}

.stack-list >>> li{
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-bottom: 20px;}
.stack-list >>> [class^="icon-"], .stack-list >>> [class*=" icon-"]{
  font-size: 35px;
  margin-left: 7px;
  margin-right: 7px;}

@media screen and (max-width: 1280px) {
  .section-presentation .skew-back{
height: calc(100% + 230px);}
}

@media screen and (max-width: 1100px) {
  .section-presentation .skew-back{
height: calc(100% + 175px);
top: 20px;
  }
}
@media screen and (max-width: 1024px) {
.hero-header .hero-header-profil{
  width: 50%;
}
.hero-header .hero-header-profil{ top: 40%;}
}
@media screen and (max-width: 960px) {
  
.hero-header .hero-header-content{
 font-size: 45px;
 height: auto;}
   .section-presentation .skew-back{
height: calc(100% + 220px);}
}
@media screen and (max-width: 768px) {
.hero-header .hero-header-content{
text-align: center;
}
.hero-header .hero-header-profil{
  left: 50%;
  right: auto;
transform: translateX(-50%);
top: 80%;
width: 70%;}
.stack-wrapper{
  flex-wrap: wrap;
}
.stack-list{
  width: 100%;
}
.section-presentation p{
  width: 100%;
}
.section-presentation .skew-back{
height : calc(100% + 165px);
top: 15px;}
}
@media screen and (max-width: 700px) {
.section-presentation .skew-back{  height: calc(100% + 195px); }
}
@media screen and (max-width: 600px) {
	.hero-header .hero-header-content{font-size: 35px;}
  .hero-header .hero-header-profil{width: 100%;}
}
@media screen and (max-width: 430px) {
  .section-presentation .home-title{
    -webkit-text-stroke: 1px #465054;
    text-stroke: 1px #465054;}
    .section-stack .skew-title[data-v-590d863e] {
  -webkit-text-stroke: 1px #e4e0da;
  text-stroke: 1px #e4e0da;
}
	.section-presentation .skew-back{ height: calc(100% + 160px); top: 50px;; }
}
</style>
